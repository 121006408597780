.Register {
    min-height: calc(100vh - 20px);
    // padding: 10px;
    .MuiFormControl-root {
        width: 100%;
    }
    .card {
        padding: 25px;
    }
    .register-btn {
        margin-top: 20px;
    }
}