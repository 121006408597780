body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
  padding: 0;
}
* {
  font-size: 14px;
}
.css-fxbtpg { 
  background-color: #f8f9fd;
}
.sidenav-content {
  background-color: #f8f9fd;
  padding: 15px 30px;
  margin: 0 !important;
  max-width: 100% !important;
}

.tabs-section {
  align-items: center;
  justify-content: space-between;
  margin-bottom:  15px;
}

.buttons-tabs {
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 14px;
  border: 1px solid #e0e2ec;
  margin-left: 25px;
  font-weight: 400;
  margin: 3px;
  background-color: #fff;
  color: #000;
}

.ceo-buttons {
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #e0e2ec;
  margin-left: 25px;
  font-weight: 400;
  margin: 3px;
  background-color: #fff;
  color: #000;
}

.info-card-header {
  font-weight: bold;
}

nav.MuiTypography-root{
  margin: 15px 0;
}

.over-charts {
  display: flex;
  /* padding-top: 5vh; */
}

.over-charts > div {
  /* margin: 0 10px; */
}

.over-charts > div > h4 {
  text-align: center;
  padding: 0 25%;
}

.over-charts > div > h6 {
  text-align: center;
  margin-top: 15px;
}

.bank-search {
  width: 90%;
  margin: 10px 3%;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-bottom: 2px solid #a3a3a3 !important;
  border-radius: 0 !important;
}

.reports-card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  padding: 20px;
  height: 100px;
}

.reports-card-container svg {
  font-size: 3rem;
  margin-right: 20px;
  border-radius: 100%;
  padding: 10px;
  background: #fff;
}

.reports-card-container > div {
  border-radius: "100px";
  height: "4rem";
}

.reports-card-container > div > :nth-child(1) {
  font-size: 2rem;
}

.button-active {
  background-color: #1ecef4;
  color: #fff;
}
.deposits-card p{
  font-size: 15px;
  font-weight: 500;
  /* width: 7rem; */
  color: #1f2c61;
}
.deposits-card label {
  display: inline-block;
  color: #808080;
  margin-top: 2px;
}

.deposits-card:hover{
  color:blue;
}

.deposits-child {
  padding:'16px';
  display: "flex";
  flex-direction: "column";
  height: '100px';
  background-color: red;
}
.deposits-card .css-1cahswr-MuiPaper-root {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
.dropdown-products {
  background-color: #fff;
  border-radius: 5px;
  border: 0 !important;
  outline: 0;
 
}
.dropdown-products .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px !important;
}
/* .dropdown-products .css-1d3z3hw-MuiOutlinedInput-notchedOutline { 
   border: 0 !important;
   outline: none;
} */
/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 10px 24px;
} */
/* .chart-section .css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-left: 17px;
} */
.chart-section h6 , .tables-section h2{
  color: #000e4b;
  font-size: 15px;
  margin-bottom: 15px;
}
.chart-section , .tables-section{
  padding: 25px 25px 25px 25px !important;
}
.chart-section .recharts-responsive-container {
  margin-left: -20px;
}
.chart-section , .tables-section {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
/* .sidenav-info {
  padding-right: 30px;
} */
.form-fields {
  align-items: center;
}
.upload-lead-buttons {
  text-align: right;
}
.leads-heading {
  color: #000e4b;
  font-weight: 500;
}
.leads-table p {
  margin-bottom: 0;
}
.leads-table .MuiDataGrid-footerContainer .MuiTablePagination-select {
  margin-top: 4px
}
.charts-section {
  margin: 0 30px;
}
.login-page {
  display: flex;
  align-items: center;
}
.login-page .carousel img {
  max-height: 100vh !important;
}
/* @media (max-width: 768px) {
  .login-page {
    flex-direction: column;
  }
  .login-page Grid {
    max-width: 100% !important 
  
}
} */

.date-picker{
  font: inherit;
  margin:"10px";
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 40px;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  border-bottom: 1px solid #868686;
}

.date-picker::after{
  border-bottom: 2px solid #1976d2;
}

.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle{
  min-height: fit-content;
  white-space: normal;
  line-height: 20px;
  
}

.date-picker:focus-visible {
  outline:none;
}

.MuiOutlinedInput-notchedOutline{
  border-bottom: 1px solid #a3a3a3 !important;
}




#demo-simple-select-standard{
  padding: 7px 4px 3px 0px;

}

#demo-simple-select-standard-label{
  margin-left: -10px;
  margin-top: 5px;

}

#label-Product-Type{
  margin-left: 5px;
}



#loanEnqTB.MuiGrid-item{
  margin-top: 0px;
}

#outlined-multiline-static-label{
  margin-left: -12px;
}